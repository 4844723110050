import React from 'react';
import {
  InfoMain,
  Box,
  Column,
  SmallTaglineBox,
  AniColumn,
  BlueTitle,
  AquaTitle,
  SmallText,
  TaglineBox,
  OtherTaglineBox,
  TaglineColumn,
  AquaSubtitle,
  Line,
  BlueSubtitle,
  FadingLine,
} from './styles.js';
//assets
import line from 'assets/lineWithDots.svg';
import InfoLeft from 'assets/InfoLeft.png';
import InfoRight from 'assets/InfoRight.png';
import useTranslation from 'hooks/useTranslation.js';
import { useResizeDetector } from 'react-resize-detector';

export const Info = ({ infoAni, years }) => {
  const { width, ref } = useResizeDetector();
  const isMobile = width < 600;
  const { t } = useTranslation();
  return (
    <InfoMain ref={ref} id="info">
      <img className="InfoLeft" alt="" src={InfoLeft} />
      <img className="InfoRight" alt="" src={InfoRight} />
      <Box>
        <Column>
          <SmallTaglineBox>
            {infoAni ? (
              <AniColumn
                className="animate__animated animate__backInLeft"
                style={{ paddingTop: '0.33rem' }}
              >
                <BlueTitle>{t('info.part1')}</BlueTitle>
                <AquaTitle>{t('info.part2')}</AquaTitle>
              </AniColumn>
            ) : (
              <Column></Column>
            )}
            <SmallText>{t('info.part3')}</SmallText>
          </SmallTaglineBox>

          <TaglineBox>
            <OtherTaglineBox>
              <TaglineColumn className="secondsInfo">
                <AquaSubtitle>{t('info.part4')}</AquaSubtitle>
                <BlueSubtitle>{t('info.part5')}</BlueSubtitle>
              </TaglineColumn>
              <Line src={line} />
              {isMobile ? (
                <TaglineColumn>
                  <AquaSubtitle>24/7</AquaSubtitle>
                  <BlueSubtitle>{t('info.part8')}</BlueSubtitle>
                </TaglineColumn>
              ) : (
                <TaglineColumn className="decisionInfo">
                  <AquaSubtitle>{t('info.part6')}</AquaSubtitle>
                  <BlueSubtitle>{t('info.part7')}</BlueSubtitle>
                </TaglineColumn>
              )}
            </OtherTaglineBox>

            <FadingLine src={line} />

            <OtherTaglineBox>
              {isMobile ? (
                <TaglineColumn>
                  <AquaSubtitle>{t('info.part6')}</AquaSubtitle>
                  <BlueSubtitle>{t('info.part7')}</BlueSubtitle>
                </TaglineColumn>
              ) : (
                <TaglineColumn>
                  <AquaSubtitle>24/7</AquaSubtitle>
                  <BlueSubtitle>{t('info.part8')}</BlueSubtitle>
                </TaglineColumn>
              )}
              <Line src={line} />
              <TaglineColumn>
                <AquaSubtitle>
                  {years()} {t('info.part9')}
                </AquaSubtitle>
                <BlueSubtitle>{t('info.part10')}</BlueSubtitle>
              </TaglineColumn>
            </OtherTaglineBox>
          </TaglineBox>
        </Column>
      </Box>
    </InfoMain>
  );
};
