/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './styles.css';
import InfoLeft from 'assets/InfoLeft.png';
import loanOfficerImageEclipse from 'assets/loanOfficerImageEclipse.webp';
import InfoRight from 'assets/InfoRight.png';
import { capitalizeFirstLetter, formatPhoneNumber, addInternationalCode } from '../../utils';

/**
 * @author Ankita Porwal
 * @dete 01/10/2024
 * @param {*} loanOfficerData loanOfficerData extracted from JSON
 * @returns MLOCard
 */
export default function MLOCard({ loanOfficerData }) {
  const loanOfficerName = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.name) : '';
  const loanOfficerNMLS = loanOfficerData ? loanOfficerData.nmls : '';
  const loanOfficerDesignation = loanOfficerData ? loanOfficerData.designation : '';
  const loanOfficerEmailAddress = loanOfficerData ? loanOfficerData.emailAddress : '';
  const loanOfficerPhoneNumber = loanOfficerData ? (loanOfficerData.mobilePhoneNumber ? loanOfficerData.mobilePhoneNumber : loanOfficerData.workPhoneNumber) : '';
  const loanOfficerPhoneNumberFormatted = loanOfficerData ? formatPhoneNumber(loanOfficerPhoneNumber) : '';
  const loanOfficerPhoneNumberWithInternationalCode = loanOfficerData ? addInternationalCode(loanOfficerPhoneNumber, '+1') : '';
  const loanOfficerPhoneNumberInTitle = loanOfficerData ? 'Contact me at ' + loanOfficerPhoneNumberFormatted : '';
  const loanOfficerStreetAddress = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.streetAddress) : '';
  const loanOfficerCity = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.city) : '';
  const loanOfficerState = loanOfficerData ? loanOfficerData.state : '';
  const loanOfficerZipCode = loanOfficerData ? loanOfficerData.zipCode : '';
  const loanOfficerCityStateZip = loanOfficerData ? loanOfficerCity + ', ' + loanOfficerState + ' ' + loanOfficerZipCode : '';
  const loanOfficerUserWebsite = loanOfficerData ? loanOfficerData.userWebsite.replace('https://', '') : '';
  const loanOfficerUserWebsiteHttp = loanOfficerData ? loanOfficerData.userWebsite : '';
  const loanOfficerProfileImage = loanOfficerData ? loanOfficerData.profileImage : '';
  
  return (
    <div id="MloWrapper">
      {/* <img className="InfoLeft" alt="" src={InfoLeft} /> */}
      <img className="InfoRight" alt="" src={InfoRight} />
      <Container>
        <Row id='mloRow' className='justify-content-center my-md-3 my-lg-5 pt-5'>
          <Col xs={8} lg={4}>
            <div className="loanOfficerContainer">
              {/* Circular image container */}
              <div className="eclipseImage">
                {/* Circular base image */}
                <img src={loanOfficerImageEclipse} alt="Loan Officer" className="eclipse" />
                <Image src={loanOfficerProfileImage}
                  className="profileImage"
                  alt={loanOfficerName} fluid />
              </div>
            </div>
          </Col>
          <Col lg={8} className='mloDetails'>
            <h4>{loanOfficerName}</h4>
            <h5>
              NMLS {loanOfficerNMLS}
              <br />
              {loanOfficerDesignation}
              <br />
              <a
                href={'mailto:' + loanOfficerEmailAddress}
                title={'Email me at ' + loanOfficerEmailAddress}
                aria-label={'Email me at ' + loanOfficerEmailAddress}
              >
                {loanOfficerEmailAddress}
              </a>
              <br />
              <a
                style={{ textDecoration: "none" }}
                href={'https://' + loanOfficerUserWebsiteHttp}
                target="_blank"
              >
                {loanOfficerUserWebsite}
              </a>

            </h5>
            <h6>Let Me Guide You Through the TRU Approval® Difference!</h6>
            <p className='callMe_BranchAddress'>
              Call or text me anytime on  <a
                href={'tel:' + loanOfficerPhoneNumberWithInternationalCode}
                title={loanOfficerPhoneNumberInTitle}
                aria-label={loanOfficerPhoneNumberInTitle}
              >
                {'D: ' + loanOfficerPhoneNumberFormatted}
              </a>
            </p>
            <p className='callMe_BranchAddress'>
              <a
                href={`https://maps.google.com/?q=${loanOfficerStreetAddress} ${loanOfficerCityStateZip}`}
                target="_blank"
              >
                {loanOfficerStreetAddress}
                <br></br>
                {loanOfficerCityStateZip}
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
