import styled from 'styled-components';
import welcome from 'assets/welcomeBack.webp';
import welcomeMobile from 'assets/WelcomeBackMobile.webp';
export const WelcomeBox = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: top left;
  margin: 0;
  z-index: 3;
  
  @media only screen and (min-width: 320px) and (max-width: 389px){
    background-image: url(${welcomeMobile});
    background-size: 100% 87%;
  }
  @media only screen and (min-width: 390px) and (max-width: 600px){
    background-image: url(${welcomeMobile});
    background-size: 100% 85%;
  }
  @media only screen and (min-width: 600px) and (max-width: 767px){
    background-size: ${process.env.REACT_APP_LANG === 'es' ? '100% 100%' : '100% 100%'};
    margin-top: -1.25rem;
    background-image: url(${welcome});
    padding-bottom:3rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px){
    background-size: ${process.env.REACT_APP_LANG === 'es' ? '100% 100%' : '100% 100%'};
    margin-top: -1.25rem;
    background-image: url(${welcome});
    padding-bottom:3rem;
  }
    @media only screen and (min-width: 992px)   {
      background-size: ${process.env.REACT_APP_LANG === 'es' ? '100% 100%' : '100% 100%'};
      background-image: url(${welcome});
      padding-bottom:5rem;
    }
    @media only screen and (min-width: 2000px)   {
      background-size: ${process.env.REACT_APP_LANG === 'es' ? '100% 100%' : '100% 100%'};
      background-image: url(${welcome});
      padding-bottom:9rem;
    }
`;
export const WelcomeContentRow = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 10vw);
  padding: 0vw 0vw;
  @media (min-width: 600px) {
    flex-direction: row;
    margin-top: 2vw;
    padding: 0 2.5vw;
  }
  @media (min-width: 1000px) {
    margin: 0;
    padding: ${process.env.REACT_APP_LANG === 'es' ? '0 4vw' : '0 3vw'};
  }
  ${process.env.REACT_APP_LANG === 'es'
    ? `
  @media (min-width: 575px) and (max-width: 991px) {
    padding: 0 6vw
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    padding: 0 2vw
  }
  `
    : ''}
`;

export const WelcomeContentColumn = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  @media (min-width: 600px) {
    align-items: flex-start;
  }
  @media (min-width: 600px) and (max-width: 991px) {
  margin-right:15px;
  margin-left: ${process.env.REACT_APP_LANG === 'es' ? '-30px' : '-20px'};
  }
  @media (min-width: 1000px) and (max-width: 1199px){
    margin-left: 0vw;
    margin-top: 2vw;
  }
  @media (min-width: 1200px) and (max-width: 1399px){
    margin-left: 1vw;
    margin-top: 2vw;
  }
  @media (min-width: 1400px) and (max-width: 1799px){
    margin-left: 1vw;
    margin-top: 2vw;
  }
  @media (min-width: 1800px) {
    margin-left: 2vw;
    margin-top: 2vw;
  }
`;
 
export const Headline1 = styled.p`
font-weight: 900!important;
@media (max-width:992px) {
    font-size: ${process.env.REACT_APP_LANG === 'es' ? '22px' : '28px'};
  }
@media screen and (min-width: 390px) and (max-width: 599px) {
    font-size: ${process.env.REACT_APP_LANG === 'es' ? '27px' : '36px'};
}
@media screen and (min-width: 600px) and (max-width: 991px) {
  font-size: ${process.env.REACT_APP_LANG === 'es' ? '29px' : '35px'};
}
@media (min-width:992px) {
    font-size: ${process.env.REACT_APP_LANG === 'es' ? '32px' : '40px'};
  }
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    font-size: ${process.env.REACT_APP_LANG === 'es' ? '38px' : '48px'};
}
@media (min-width:1400px) {
  font-size: ${process.env.REACT_APP_LANG === 'es' ? '44px' : '53px'};
}
`;
export const Headline = styled.h4`
  color: white;
  text-align: center;
  width: 100%;
  padding: 5vw 0vw 0vw 0vw;
  font-size: ${process.env.REACT_APP_LANG === 'es' ? '5vw' : '5.75vw'};
  font-weight: 700!important;
  @media (min-width: 600px) {
    padding: 1vw 0 0 0;
    text-align: left;
  }
  @media (min-width:992px) {
    text-align: left;
    font-weight:700!important;
  }
`;
export const Spacer = styled.div`
  height: 2rem;
  width: 100%;
  @media (min-width: 600px) {
    display: none;
  }
`;
export const VideoPic = styled.img`
  width: 80%;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    width: 40vw;
  }
  @media (min-width: 600px) and (max-width: 767px) {
    width: ${process.env.REACT_APP_LANG === 'es' ? '29vw' : '33vw'};
    margin-left: ${process.env.REACT_APP_LANG === 'es' ? '-10px' : '-1px'};
    }
  @media (min-width: 768px) and (max-width: 991px) {
    width: ${process.env.REACT_APP_LANG === 'es' ? '29vw' : '33vw'};
    margin-left: ${process.env.REACT_APP_LANG === 'es' ? '0px' : '0px'};
    }
  @media (min-width: 992px) {
   margin-top:3rem;
   width: 35vw;
  }
`;
export const VideoPicMobile = styled.img`
  width: 80%;
  @media (min-width: 601px) {
    display: none;
    width: 40vw;
  }
`;
export const Truline = styled.p`
  font-size: 13px;
  color: white;
  font-align: left;
  line-height: 1.3;
  width: 100%;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  @media (max-width: 600px) {
    padding: 0vw;
  }
  @media (max-width: 991px) {
    padding-left:10px;
  }
  @media (min-width: 390px) and (max-width: 599px) {
    font-size:4vw!important;
  }
  @media (min-width: 600px) and (max-width: 999px) {
    font-size: 1.75vw;
    text-align: left;
    padding: 0vw;
  }
  @media (min-width: 992px) {
    text-align: left;
    padding: 0vw;
    margin-bottom:0px;
    padding-top:10px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:10px!important;
  `
    : ''}
  } 
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 14px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:10px!important;
  `
    : ''}
  }
  @media (min-width: 1400px)  {
    font-size: 16px;
    padding-top:8px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:14px!important;
  `
    : ''}
  }
`;

export const Subline = styled.p`
  color: white;
  font-align: left;
  padding: 0vw 0vw 3vw 0vw;
  font-size: 13px;
  line-height: 1.3;
  width: 100%;
  text-align: left;
  font-weight: 600;
  @media (max-width: 599px) {
    margin-bottom: 0px;
  }
  @media (max-width: 991px) {
    padding-left:10px;
  }
  @media (min-width: 600px) and (max-width: 999px) {
    // padding: 1vw 0vw;
    font-size: 1.75vw;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    margin-bottom: ${process.env.REACT_APP_LANG === 'es' ? '1rem' : '0rem'};
    margin-top: ${process.env.REACT_APP_LANG === 'es' ? '1em' : '0.5em'};
    padding-left:1px;
  }
  @media (min-width: 992px) {
    padding: 0vw 0vw;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    padding-top:10px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:10px!important;
  `
    : ''}
  }
  @media (min-width: 390px) and (max-width: 599px) {
    font-size:4vw!important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 14px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:10px!important;
  `
    : ''}
  }
  @media (min-width: 1400px)  {
    font-size: 16px;
    padding-top:8px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:14px!important;
  `
    : ''}
  }
`;
export const TermlinePara = styled.p`
  font-size:13px;
  color: white;
  font-align: left;
  line-height: 1.3;
  width: 100%;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 0;
  @media (max-width: 600px) {
    padding-bottom: 5vw;
  }
  @media (max-width: 991px) {
    padding-left:10px;
  }
  @media (min-width: 390px) and (max-width: 599px) {
    font-size:4vw!important;
  }
  @media (min-width: 600px) and (max-width: 999px) {
    font-size: 1.75vw;
    text-align: left;
    padding: 0vw;
  }
  @media (min-width: 992px) {
    text-align: left;
    padding: 0vw;
    padding-top:10px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:9px!important;
  `
    : ''}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 14px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:10px!important;
  `
    : ''}
  }
  @media (min-width: 1400px)  {
    font-size: 16px;
    padding-top:8px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:14px!important;
  `
    : ''}
  }
`;
export const Termline = styled.a`
font-size:13px;
  color: white;
  font-align: left;
  line-height: 1.3;
  width: 100%;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 600px) {
    padding-bottom: 5vw;
  }
  @media (min-width: 390px) and (max-width: 599px) {
    font-size:4vw!important;
  }
  @media (min-width: 600px) and (max-width: 999px) {
    font-size: 1.75vw;
    text-align: left;
    padding: 0vw;
  }
  @media (min-width: 992px) {
    text-align: left;
    padding: 0vw;
    padding-top:10px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:9px!important;
  `
    : ''}
  }
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 11px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 14px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:10px!important;
  `
    : ''}
  }
  @media (min-width: 1400px)  {
    font-size: 16px;
    padding-top:8px;
    ${process.env.REACT_APP_LANG === 'es'
    ? `
 font-size:14px!important;
  `
    : ''}
  }
`;

export const BottomLine = styled(Subline)`
  font-weight: 500;
  font-size: 3.25vw;
  width: 100%;
  padding: 3vw 0vw 0vw 0vw;
  margin-right: 0vw;
  text-align: center!important;
 
  @media (min-width: 600px) {
    font-weight: 500;
    font-size: 1.5vw;
    padding: 0vw 0vw 0vw 0vw;
    text-align: left!important;
  }
  ${process.env.REACT_APP_LANG === 'es'
    ? `
  @media (min-width: 575px) and (max-width: 999px) {
    padding: 0;
    margin-top: 5px;
    margin-left: 15px;
  }
  @media (min-width: 320px) and (max-width: 599px) {
    padding: 0;
    margin-top: 5px;
    margin-left: 15px;
  }
  @media (max-width: 575px) {
    padding: 0;
    margin-top: 10px;
    margin-left: 0px;
  }
  `
    : ''}
  @media (min-width: 1000px) {
    padding: ${process.env.REACT_APP_LANG === 'es' ? '0.4vw 0vw 0vw 3vw' : '1vw 0vw 0vw 0vw'};
    margin: 0.5vw;
    font-size: 1.3vw;
  }
`;
export const PuzzlePieces = styled.img`
  position: absolute;
  height: 40vw;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 21vw;
  margin-left: 24vw;
  @media (max-width: 600px) {
    right: 15vw;
    top: 12vw;
  }
  @media (min-width: 600px) {
    height: 20vw;
    margin-top: 11vw;
    margin-left: 13.3vw;
    z-index: 2;
    right: 4vw;
    top: 10vw;
  }
`;

export const CurvedArrow = styled.img`
  position: absolute;
  margin-left: 40vw;
  z-index: 1;
  height: 10vw;
  @media (max-width: 599px) {
    margin-top: ${process.env.REACT_APP_LANG === 'es' ? '35vw' : '32vw'};
    bottom: 1vw;
    right: -3vw;
  }
  @media only screen and (min-width: 600px) {
    margin-left: 37vw;
    margin-top: ${process.env.REACT_APP_LANG === 'es' ? '14vw' : '13vw'};
    height: 5vw;
    bottom: ${process.env.REACT_APP_LANG === 'es' ? '2.3vw' : '0.3vw'};
  }
  @media only screen and (min-width: 1000px) {
    margin-top: ${process.env.REACT_APP_LANG === 'es' ? '12vw' : '11vw'};
    margin-left: 38vw;
    bottom: 0.7vw;
  }
`;
export const Person1 = styled.img`
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  background: white;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  display: none;

  border: 3px solid white;
  @media (min-width: 600px) {
    display: flex;
    height: 4vw;
    border: 2px solid white;
    border-top-color: #1c2253;
    border-bottom-color: #1c2253;
    margin-left: -19.5vw;
    margin-top: -5vw;
  }
`;
export const Person2 = styled(Person1)`
  @media (min-width: 600px) {
    display: flex;
    margin-left: -6.5vw;
    margin-top: -18.75vw;
  }
`;
export const Person3 = styled(Person1)`
  @media (min-width: 600px) {
    display: flex;

    margin-left: 17.25vw;
    margin-top: -10vw;
  }
`;
export const Person4 = styled(Person1)`
  @media (min-width: 600px) {
    display: flex;
    margin-left: -19.25vw;
    margin-top: 7vw;
  }
`;
export const Person5 = styled(Person1)`
  @media (min-width: 600px) {
    display: flex;
    margin-left: -9.5vw;
    margin-top: 18vw;
  }
`;
export const Person6 = styled(Person1)`
  @media (min-width: 600px) {
    display: flex;
    margin-top: 0vw;
    margin-left: 20.5vw;
  }
`;

export const Play = styled.img`
  margin-left: 2px;
  height: 3vw;
  @media (min-width: 600px) {
    height: 1.5vw;
  }
`;
export const SolidPlayRing = styled.div`
  background-color: #ffffff;
  padding: 3vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 600px) {
    padding: 2vw;
  }
  @media (min-width: 1000px) {
    padding: 1.5vw;
  }
`;
export const OpacPlayRing = styled.div`
  border: 0.25rem solid rgb(255, 255, 255, 0.5);
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  transition: border-color 0.4s;
  cursor: pointer;
  @media (min-width: 600px) {
    left: 11.5vw;
    top: 13vw;
  }
  @media (min-width: 992px) {
    left: 11.5vw;
    top: 14vw;
  }
`;

export const WelcomeMedia = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: ${process.env.REACT_APP_LANG === 'es' ? '2.5vw' : '1vw'};
  }
`;

export const Padding = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;

  padding: 22vw;
  &:hover ${OpacPlayRing} {
    border-color: #19c1f3;
  }
  @media only screen and (min-width: 600px) {
    padding: 15vw;
  }
  @media only screen and (max-width: 991px) {
    margin-top:-2rem !important;
    margin-left:-1.5rem !important;
  }
`;

