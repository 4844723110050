/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import AgentHomePage from './AgentHomePage';
import { CookieSetting } from "cookiesetting-component";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:agentName" element={<AgentHomePage />} />
      </Routes>
      <CookieSetting
       privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
       configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
       analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </React.Fragment>
  );
}

export default App;
