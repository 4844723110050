/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

import React, { useCallback, useState } from "react";
import { SwmcFooter } from "footer-component";

import "./index.css";

const CustomFooter = () => {
  return (
    <div>
      <SwmcFooter type="swmc" showTacanewLogo={true} nydisclaimer={false} />
    </div>
  );
};

export default CustomFooter;