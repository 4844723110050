import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body {
    font-family: 'Inter';
  scroll-behavior: smooth;
padding:0;
margin:0;
};

h1{
    font-weight:900;
};
h2{
    font-weight:800;
};
h3{
    font-weight:700;
};
h4{
    font-weight:600;
};
h5{
    font-weight:500;
};
h6{
    font-weight:400;
};
p{
    font-weight:400;
};
strong{
    font-weight:700;
};
a:link{
    text-decoration:none;
};
`;

export default GlobalStyle;
