/**
 * Component to show Video Section.
 * Its elements are a carousel, a modal which shows video opened, and a modal which has gallery view for videos.
 * Height is being passed to the two modals , in order to have dynamic height for better responsiveness.
 * From the youtube videos' links received from client, we are using id to egenrate thumbnail and src link.
 */

import React, { useState, Suspense, lazy, useEffect } from 'react';
import { Image, Col, Container, Row } from 'react-bootstrap';
import 'styles/gallerysection.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useTranslation from 'hooks/useTranslation.js';

import { Main } from './styles.js';
import Loader from 'components/Loader.js';
import axios from 'axios';
import testimonialVideos from 'assets/json/testimonials.json';
const Video = lazy(() => import('./Video'));
const imagePath = 'https://d2w24n4g34usfg.cloudfront.net/wholesale-websites';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const GallerySection = () => {
  const { t } = useTranslation();
  const [isOpenSingleVideo, setIsOpenSingleVideo] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [videoArray, setVideoArray] = useState(testimonialVideos);
  
  const getVideos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CDN_IMAGES}testimonials.json`
      );
      if (response.status === 200) {
        setVideoArray(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getVideos();
  }, []);

  const handleThumbnailClick = (e, id) => {
    e.preventDefault();
    setIsOpenSingleVideo(true);
    setCurrentVideoId(id);
  };

  const handleCustomArrowClick = (e, arrowType) => {
    e.preventDefault();
    const domInbuiltArrowName = arrowType === 'Next' ? 'right' : 'left';
    const inbuiltArrow = document.querySelectorAll(
      '.react-multiple-carousel__arrow.react-multiple-carousel__arrow--' + domInbuiltArrowName
    );

    if (inbuiltArrow && inbuiltArrow.length === 1) {
      inbuiltArrow[0].click();
    }
  };
  const handleOverlayCloseClick = (e) => {
    setIsOpenSingleVideo(false);
    setCurrentVideoId('');
  };
  return videoArray?.length > 0 ? (
    <Main>
      <Container className="faq my-2 my-md-4">
        <Row>
          <Col xs={12} className="text-center mb-4 sm-margin">
            <div className="table-title broker-videos-title">{t('testimonial.title')}</div>
          </Col>
          <Col xs={12}>
            {' '}
            <div className="gallery-section-container pt-1">
              <div className="video-grid">
                <div
                  className="slider-custom-arrows video-grid-item arrow-left"
                  style={{
                    backgroundImage: `url(${imagePath}/video/arrow_left.svg)`,
                  }}
                  onClick={(e) => handleCustomArrowClick(e, 'Previous')}
                ></div>
                <div
                  className="slider-custom-arrows video-grid-item arrow-right"
                  style={{
                    backgroundImage: `url(${imagePath}/video/arrow_right.svg)`,
                  }}
                  onClick={(e) => handleCustomArrowClick(e, 'Next')}
                ></div>
                <div className="middle-section video-grid-item">
                  <Carousel
                    additionalTransfrom={0}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite
                    centerMode={true}
                    autoPlay={false}
                    shouldResetAutoplay={false}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {videoArray?.map((item, index) => (
                      <div key={index} className="thumbnail-container">
                        <div
                          className={`image-wrapper ${
                            item.id === currentVideoId ? 'now-playing' : ''
                          }`}
                        >
                          <Image
                            className="thumbnail-image"
                            src={item.thumbnail}
                            onClick={(e) => {
                              handleThumbnailClick(e, item.id);
                            }}
                            draggable="false"
                            width="320"
                            height="180"
                            alt=""
                            srcSet={`${item.thumbnail} 1650w, ${item.thumbnail} 300w, ${item.thumbnail} 768w,`}
                            sizes="(max-width:1650px) 100vw, 1650px"
                            style={{ height: 'auto' }}
                          ></Image>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>

              <Suspense fallback={<Loader />}>
                {isOpenSingleVideo && (
                  <Video
                    onClose={handleOverlayCloseClick}
                    url={videoArray?.find((el) => el.id === currentVideoId)?.url}
                  />
                )}
              </Suspense>
            </div>
          </Col>
        </Row>
      </Container>
    </Main>
  ) : (
    <></>
  );
};

export default GallerySection;
