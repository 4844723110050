import React from 'react';
import styled, { keyframes } from 'styled-components';
import { formatPhoneNumber, addInternationalCode } from '../../utils';

export const ContactPhone = ({ icon, font, loanOfficerData }) => {

  //If no phone number is available, use this default number
  const loanOfficerPhoneNumber = loanOfficerData ? (loanOfficerData.mobilePhoneNumber ? loanOfficerData.mobilePhoneNumber : loanOfficerData.workPhoneNumber) : '';
  const loanOfficerPhoneNumberFormatted = loanOfficerData && loanOfficerPhoneNumber !== undefined ? formatPhoneNumber(loanOfficerPhoneNumber) : '(855) 999-1213';
  const loanOfficerPhoneNumberWithInternationalCode = loanOfficerData && loanOfficerPhoneNumber !== undefined ? addInternationalCode(loanOfficerPhoneNumber, '+1') : '+18559991213';

  return (
    <Box as="a" href={`tel:${loanOfficerPhoneNumberWithInternationalCode}`}>
      <Icon src={icon} />
      <Number font={font}>{loanOfficerPhoneNumberFormatted}</Number>
    </Box>
  );
};

export const enter = keyframes`
0%{
  color:rgb(25,193,243); background:white;
}
100%{
color:white;background:#1C2253;
}
`;
export const exit = keyframes`
0%{
  color:white;background:#1C2253;
  }
100%{
  color:rgb(25,193,243); background:white;
}
`;

export const Box = styled.span`
  height: 100%;
  z-index: 3;
  background: white;
  border-radius: 100px;
  display: flex;
  color: rgb(25, 193, 243);
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  text-decoration: none;
  animation: ${exit} 0.3s linear 0s 1;
  &:hover {
    animation: ${enter} 0.3s linear 0s 1 forwards;
  }

  @media (min-width: 600px) {
    padding: 0.5vw;
  }
`;

export const Icon = styled.img`
  padding: 0;
  margin: 0;
  background-color: rgb(25, 193, 243);
  border-radius: 50%;
  margin-right: 1px;
  height: 6vw;
  width: 6vw;
  padding: 0.25rem;
  @media only screen and (min-width: 600px) {
    height: 3.25vw;
    width: 3.25vw;
    padding: 1vw;
  }
  @media only screen and (min-width: 1000px) {
    height: 3vw;
    width: 3vw;
    padding: 1vw;
  }
`;
export const Number = styled.p`
  padding: 0;
  margin: 0;
  padding: 0vw 1vw 0vw 1vw;
  font-size: 3vw;
  @media (min-width: 600px) {
    font-size: 1.5vw;
    margin-top: 3px;
  }
  @media (min-width: 1000px) {
    font-size: 1.25vw;
    margin-top: 3px;
  }
`;
