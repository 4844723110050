import styled from 'styled-components';

const StyledLoader = styled.div`
  display: flex;
  align-self: center;
  border: 3.5px solid #00aae4;
  border-top: 3.5px solid white;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '5',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <StyledLoader />
    </div>
  );
};

export default Loader;
