import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  background: #00AAE4;
  padding: 0.5rem 0rem;
  z-index: 2;
  @media (min-width: 600px) {
    width: calc(100%);
  }
`;
export const Spacer = styled.div`
  height: 100%;
`;
export const TRUApprovalLogo = styled.img`
  @media (max-width: 600px) {
    height: 3.75rem;
    margin-bottom: 1vw;
    margin-top: -2vw;
  }
  @media (max-width: 340px) {
    height: 3.75rem;
    width: 155px;
    margin-bottom: 1vw;
    margin-top: -2vw;
  }
`;
