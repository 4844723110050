/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React, { useEffect, useLayoutEffect, useState, Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import StyledApp from './styles/StyledApp.js';
import GlobalStyle from './styles/GlobalStyle.js';
// import Disclaimer from './Layout/Disclaimer';
import { FAQ } from './Layout/FAQ/FAQ.js';
import { Info } from './Layout/Info/Info.js';
import { Offer } from './Layout/Offer/Offer.js';
// import { Product } from './Layout/Product/Product';
import { Steps } from './Layout/Steps/Steps.js';
import { Header } from './Layout/Header/Header.js';
import { Welcome } from './Layout/Welcome/Welcome.js';
import Testimonials from './Layout/Testimonials/index.js';
import MLOCard from 'Layout/MLOCard/MLOCard.js';
import Loader from 'components/Loader.js';
import KeySteps from 'Layout/KeySteps/index.js';
import Background from 'Layout/Background/index.js';
import Tips from 'Layout/Tips/index.js';
import Footer from 'Layout/Footer/Footer.js';
const FormModal = lazy(() => import('./Layout/FormModal/FormModal.js'));
const Video = lazy(() => import('./Layout/Video/Video.js'));

/**
 * @author Ankita Porwal
 * @date 01/10/2024
 * @description Component to show tru approval page for an agent.
 * @returns
 */
function AgentHomePage() {
  const { agentName } = useParams();
  const agentJsonName = agentName + '.json';
  const [loading, setLoading] = useState(true);
  const [agentData, setAgentData] = useState();
  const [loanOfficerData, setLoanOfficerData] = useState();
  const [infoAni, setInfoAni] = useState(false);
  const [offerAni, setOfferAni] = useState(false);
  const [videoToggle, setVideoToggle] = useState(false);
  const [played, setPlayed] = useState(false);
  const [show, setShow] = useState(false);
  const [validate, setValidate] = useState(false);
  const [toggle, setToggle] = useState([]);
  const [truTermsLink, setTruTermsLink] = useState(process.env.REACT_APP_TRU_TERMS_URL);

  useEffect(() => {
    try {
      setTimeout(() => {
        const ascript = document.createElement('script');
        ascript.src = 'https://d2w24n4g34usfg.cloudfront.net/js/analytics.min.js';
        ascript.async = true;
        document.body.appendChild(ascript);

        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-M23BKJ3');

        (function (d) {
          var s = d.createElement('script');
          s.setAttribute('data-account', 'RF3LDqOACA');
          s.setAttribute('src', 'https://cdn.userway.org/widget.js');
          (d.body || d.head).appendChild(s);
        })(document);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const faqItems = document.querySelectorAll('.faqItem').length;
    let toggleArr = Array(faqItems).fill(false);
    toggleArr[0] = true;
    setToggle(toggleArr);
  }, []);

  useLayoutEffect(() => {
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop + window.innerHeight;
    const info = document.getElementById('info');
    if (info && info.offsetTop <= docViewBottom && info.offsetTop >= docViewTop) {
      setInfoAni(true);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onScroll = () => {
    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop + window.innerHeight;
    const info = document.getElementById('info');
    const offer = document.getElementById('offer');
    if (info && info.offsetTop <= docViewBottom && info.offsetTop >= docViewTop) {
      setInfoAni(true);
    }
    if (offer && offer.offsetTop <= docViewBottom && offer.offsetTop >= docViewTop) {
      setOfferAni(true);
    }
  };

  const handleToggle = (idx, toggle) => {
    let newArray = Array(toggle.length).fill(false);
    let newBool = !toggle[idx];
    newArray[idx] = newBool;
    setToggle(newArray);
  };

  const handleVideo = (videoToggle) => {
    setVideoToggle(!videoToggle);
    setPlayed(true);
  };
  const handleClick = () => {
    setValidate((validate) => (validate = !validate));
    setShow((show) => (show = !show));
  };

  // const years = () => {
  //   let founding = new Date('Aug 16, 1980 23:59:59');
  //   let today = new Date();
  //   return today.getFullYear() - founding.getFullYear();
  // };
  const years = () => {
    // Set the time component of the founding date to midnight
    let founding = new Date('Aug 12, 1980 00:00:00');
    let today = new Date();
    // Calculate the difference in years
    let differenceInMilliseconds = today - founding;
    let years = Math.floor(differenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
    return years;
  };
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchAgentDetails = async () => {
      try {
        console.log(`agentJsonName: ${agentJsonName}`);
        await axios
          .get(`${process.env.REACT_APP_CDN}swmc/jsons/loanOfficer/loanOfficerJson/${agentJsonName}`)
          .then(async (response) => {
            // console.log(JSON.stringify(response.data));
            const responseData = response.data;
            setAgentData(responseData);
            if (responseData) {
              setLoading(false);
              if (responseData.loanOfficer) {
                setLoanOfficerData(responseData.loanOfficer);
                setTruTermsLink(truTermsLink + "?rlo=" + responseData.loanOfficer.ssid);
              } else {
                setLoanOfficerData(null);
              }
            }
            else {
              window.location.replace('https://www.swmc.com/truapproval/');
            }
          })
          .catch((error) => {
            window.location.replace('https://www.swmc.com/truapproval/');
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchAgentDetails();
  }, []);

  return (
    <React.Fragment>
      <div className="bodyContainer">
        {loading ? (
          <div className="mt-5 mb-3 text-center">
            <Spinner animation="border" />
            <h5>Loading...</h5>
          </div>
        ) : (
          <>
            {agentData ? (
              <>
                <StyledApp className="">
                  <Background />
                  <GlobalStyle />
                  <Header loanOfficerData={loanOfficerData} />
                  <Welcome
                    handleVideo={handleVideo}
                    videoToggle={videoToggle}
                    handleClick={handleClick}
                    truTermsLink={truTermsLink}
                  />
                  <MLOCard loanOfficerData={loanOfficerData} />
                  <Info years={years} infoAni={infoAni} />
                  <Steps handleClick={handleClick} />
                  {process.env.REACT_APP_LANG !== 'es' && <Tips />}
                  {/* <Product /> */}
                  <Offer offerAni={offerAni} years={years} handleClick={handleClick} truTermsLink={truTermsLink} />
                  <KeySteps />
                  <FAQ handleToggle={handleToggle} toggle={toggle} />
                  <Testimonials />
                  {/* <Disclaimer /> */}
                  <Suspense fallback={<Loader />}>
                    {show && (
                      <FormModal show={show} buttonClicked={handleClick} validate={validate} loanOfficerData={loanOfficerData} />
                    )}
                  </Suspense>
                  <Suspense fallback={<Loader />}>
                    {played && <Video handleVideo={handleVideo} videoToggle={videoToggle} />}
                  </Suspense>
                </StyledApp>
                <Footer year={currentYear} loanOfficerData={loanOfficerData} />
              </>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default AgentHomePage;
