import styled, { keyframes } from 'styled-components';

export const StyledModal = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  z-index: 5;
  overflow: auto;
  height: auto;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: white;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 575px) {
    background: rgb(88, 88, 88, 0.8);
  }
  @media only screen and (min-height: 675px) and (min-width: 700px) {
    align-items: center;
  }

  .truapFormWrapper {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .truapFormHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
    border-radius: 5px 5px 0px 0px;
    background-color: #2ba8e0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 1px solid #2ba8e0;
    border-top: none;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    @media (max-width: 600px) {
      padding: 0.75em;
      font-size: 16px;
    }
  }

  .truapFormBody {
    border: 1px solid #2ba8e0;
    border-top: none;
    position: relative;
    padding-top: 1rem;
  }

  .truapFormBody.es .consent-font .form-link:nth-of-type(2) {
    margin-right: 0.4em;
  }
`;
export const FormBox = styled.div`
  z-index: 5;
  background: white;
  border-radius: 0.5rem;
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 700px;
  h6 {
    font-weight: 700;
  }
  .consent-font {
    font-size: 11px;
  }
  .checkIConsent {
    font-weight: 700 !important;
  }
  .blg-container {
    padding-top: 0 !important;
  }
  .blg-col-0 {
    display: none;
  }
  .btn-submit {
    margin-top: 12px !important;
    margin-bottom: 0 !important;
  }
  @media (min-width: 575px) {
    margin: 1.5rem 0rem;
  }
  .pt-3 {
    padding-top: 0px !important;
  }
  #formHeader1 {
    margin-bottom: 0px !important;
    font-size: 1.4em !important;
  }
  .form-titles-section {
    svg {
      cursor: pointer;
    }
  }
  .consent-font {
    font-size: 11px;
  }
`;
export const BackButton = styled.div`
  position: absolute;
  bottom: 2px;
  left: 1%;

  .btn-submit {
    background-color: #6c757d;
    border-color: #6c757d;
    font-weight: 600;
  }
`;
export const CloseWindow = styled.div`
  overflow: auto;
  height: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  z-index: 1;
`;
export const fluctuate = keyframes`
    0%{transform:scale(0.95);}
    50%{transform:scale(1.05);}
    100%{transform:scale(0.95);}
    `;
export const Close = styled.img`
  padding: 0.75vw 0.75vw 0.75vw 0.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  /*   margin-top: -0.5vw;
  margin-right: -0.5vw; */
  @media (min-width: 600px) {
    background: white;
    margin-top: -1vw;
    margin-right: -1vw;
  }
  &:hover {
    @media (min-width: 600px) {
      background: rgb(25, 193, 243);
    }
    z-index: 10;
    animation: ${fluctuate} 1.3s infinite ease-in-out;
  }
`;
