import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { VideoMain, VideoBox, Close } from './styles.js';
import x from 'assets/xIcon.svg';

const Video = ({ onClose, url }) => {
  return (
    <VideoMain
      className="animate__animated animate__fadeIn"
      videoToggle={true}
      onClick={() => onClose()}
    >
      <VideoBox className="animate__animated animate__bounceInUp">
        <Close src={x} />
        <ReactPlayer width="100%" height="100%" playing={true} controls={true} url={url} />
      </VideoBox>
    </VideoMain>
  );
};
export default Video;
