import styled from 'styled-components';
import background from 'assets/OfferBG.svg';

export const OfferMain = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5vw 0vw 5vw 0vw;
  flex-direction: column-reverse;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  position: relative;
  @media (max-width: 599px) {
    .mobileOfferDesign {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  @media (min-width: 600px) {
    padding: 2.5vw 0vw 8vw 0;
    flex-direction: row;
    margin-top: 4vw;
    .mobileOfferDesign {
      display: none;
      position: absolute;
    }
  }
`;
export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (min-width: 600px) {
    justify-content: space-between;
    align-items: flex-start;
  }
`;
export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const RightColumn = styled(Column)`
  width: 100%;
  height: 100%;
  align-items: space-between;
  @media only screen and (min-width: 600px) {
    height: 25vw;
    padding: 0vw 4vw 0vw 4vw;
  }
`;
export const LeftColumn = styled(Column)`
  justify-content: space-between;
  height: 100%;
  @media (min-width: 600px) {
    justify-content: center;
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media (min-width: 600px) {
    justify-content: flex-end;
  }
`;
export const RowText = styled(Row)`
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 5px;

  @media only screen and (min-width: 600px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CoupleBack = styled.img`
height:40vw;
padding 0vw 2vw;
@media(min-width:600px){
    height:20vw;
      }
`;
export const CoupleFront = styled.img`
  height: 50vw;
  @media (min-width: 600px) {
    height: 25vw;
  }
`;

export const Dots = styled.img`
  position: absolute;
  z-index: -1;
  height: 20.1vw;
  margin-left: -7vw;
  margin-top: 25vw;
  @media (min-width: 600px) {
    height: 6.75vw;
    margin-left: -5.25vw;
    margin-top: 8.5vw;
  }
`;

export const TextBox = styled.span`
  display: flex;
  background: #19c1f3;
  margin-left: 28vw;
  margin-top: 43vw;
  z-index: 1;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1vw 1.5vw;
  @media only screen and (min-width: 600px) {
    padding: 1.1vw 1.5vw;
    margin-left: 9vw;
    margin-top: 26.3vw;
  }
  @media only screen and (min-width: 1000px) {
    padding: 0.8vw 1.5vw 0.5vw 1.5vw;
  }
`;

export const BoldText = styled.h3`
  font-size: 2.75vw;
  white-space: nowrap;
  @media (min-width: 600px) {
    font-size: 2vw;
  }
  @media (min-width: 1000px) {
    font-size: 1.5vw;
  }
`;

export const Text = styled.h5`
  font-size: 2.5vw;
  white-space: nowrap;
  @media (min-width: 600px) {
    font-size: 1.5vw;
  }
  @media (min-width: 1000px) {
    font-size: 1vw;
  }
`;

export const Title = styled.h1`
  font-size: 6.5vw;
  color: #1c2253;
  text-align: left;
  font-weight: 600;
  line-height: 121%;
  letter-spacing: -0.01em;
  width: calc(100% - 7.5vw);
  @media (min-width: 600px) {
    font-size: 2.75vw;
    width: 70%;
  }
  @media (min-width: 1000px) {
    font-size: 2.4vw;
    width: 60%;
    width: 70%;
  }
`;

export const Subtitle = styled.span`
  font-size: 6.75vw;
  color: #19c1f3;
  text-align: left;
  font-weight: 600;
  line-height: 121%;
  letter-spacing: -0.01em;
  @media (min-width: 600px) {
    font-size: 2.4vw;
    width: 70%;
  }
`;
export const List = styled.ul`
  list-style-type: disc;
  padding: 1.5vw 0vw 1.5vw 0vw;
  height: 100%;
  margin-left: 0.5rem;
  @media (min-width: 600px) {
    margin-left: 1.6rem;
  }
`;

export const ListItem = styled.li`
  color: #5c5e70;
  font-size: 3.75vw;
  padding: 0.5vw 0vw 0.5vw 0vw;
  white-space: nowrap;
  font-weight: 500;
  line-height: 208%;
  @media (min-width: 600px) {
    font-size: 1.5vw;
    width: 100%;
    line-height: 1;
  }
  @media (min-width: 1000px) {
    font-size: 1.25vw;
  }
`;
export const TruBox = styled.div`
  padding: 3vw 3vw 5vw 3vw;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    padding: 0;
    flex-direction: row;
  }
`;
export const TRUTermButton = styled.a`
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #19c1f3;
  text-decoration: none;
  line-height: 189%;
  white-space: nowrap;
  margin: 0;
  padding: 1vw 7vw;
  font-size: 16px;
  font-weight: 500;
  background: white;
  box-shadow: 0px 9px 60px rgba(28, 191, 239, 0.2);
  border-radius: 20px;
  @media (max-width: 600px) {
    min-width: 100%;
  }
  @media (min-width: 600px) {
    padding: 1.1vw 2vw 1.1vw 2vw;
    font-size: 1.5vw;
    border-radius: 4vw;
  }
  @media (min-width: 999px) {
    padding: 0.825vw 2vw 0.825vw 2vw;
    font-size: 1.25vw;
    border-radius: 4vw;
  }
`;
