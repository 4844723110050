import styled from 'styled-components';

export const FaqMain = styled.section`
  width: calc(100%);
  height: 100%;
  display: flex;
  padding: 3vw 0vw 0vw 0vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    padding: 7vw 0vw 0vw 0vw;
  }
  .wrap-reverse {
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 601px) {
    .FAQLeft {
      position: absolute;
      left: 0vw;
      width: 5vw;
    }
    .FAQRight {
      position: absolute;
      right: 5vw;
      width: 1vw;
    }
  }
  @media only screen and (max-width: 600px) {
    .FAQLeft {
      display: none;
    }
    .FAQRight {
      display: none;
    }
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: 0;
  width: 100%;
  @media (min-width: 600px) {
    width: calc(100% - 2.5rem);
    margin: 1.25vw;
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`;
export const Box = styled(Row)`
  flex-direction: column;
  width: calc(100% - 7.5vw);
  @media (min-width: 600px) {
    width: 80%;
    flex-direction: row;
  }
  @media (min-width: 1000px) {
    width: 70%;
    flex-direction: row;
  }
`;

export const Title = styled.h6`
  color: #1c2253;
  font-weight: 600;
  line-height: 121%;
  text-align: center;
  letter-spacing: -0.01em;
  font-size: 6.5vw;
  padding: 1vw;
  @media (min-width: 600px) {
    padding: 0.33vw;
    font-size: 2.75vw;
  }
  @media (min-width: 1000px) {
    padding: 0.33vw;
    font-size: 2.4vw;
  }
`;
export const Subtitle = styled(Title)`
  color: #19c1f3;
`;

export const DisclaimerBox = styled.div`
  background: rgba(0, 170, 228, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1vw;
`;
export const Disclaimer = styled.p`
  font-weight: 400;
  line-height: 93.75%;
  padding: 1vw 1vw 0vw 1vw;
  color: #000000;
  font-size: 12px;
  width: 95%;
  @media only screen and (min-width: 600px) {
    width: 75%;
    font-size: 0.7vw;
    font-weight: 600;
  }
  @media only screen and (min-width: 1000px) {
    width: 70%;
    font-weight: 400;
    font-size: 0.7vw;
  }
`;
