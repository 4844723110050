import styled from 'styled-components';

const StyledApp = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  :root {
    --animate-duration: 1.75s;
  }
`;

export default StyledApp;
