import styled, { keyframes } from 'styled-components';
import previousButton from '../../assets/previousButton.png';
import nextButton from '../../assets/nextButton.png';
import halfDots from '../../assets/halfDots.svg';
export const fluctuate = keyframes`
0%{transform:scale(0.95);}
50%{transform:scale(1.05);}
100%{transform:scale(0.95);}
`;
const StyledTips = styled.section`
  width: 100%;
  padding: 2rem 0;
  background-color: #ebfafe;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${halfDots});
  background-repeat: no-repeat;
  background-position: 0 17rem;
  & .carousel-button-group {
    transform: translate(0, -180px);
    height: 0rem;
  }
  & :where(.previousButton, .nextButton) {
    transform: translate(-60px, 0);
    width: 3rem;
    height: 3rem;
    background-image: url(${previousButton});
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  & .nextButton {
    transform: translate(60px, 0);
    background-image: url(${nextButton});
  }
  & .redPlay {
    position: absolute;
    z-index: 10;
  }

  & .img-hover {
    cursor: pointer;
  }
  & .carousel-image {
    max-height: 300px;
  }

  & .img-hover:hover .redPlay {
    animation: ${fluctuate} 1.3s infinite ease-in-out;
  }
  & .image-item {
    padding: 0 0.5rem;
  }
  @media (max-width: 1425px) {
    & .previousButton {
      transform: translate(-50px, 0);
    }
    & .nextButton {
      transform: translate(50px, 0);
    }
  }
  @media (max-width: 1400px) {
    & .previousButton {
      transform: translate(-60px, 0);
    }
    & .nextButton {
      transform: translate(60px, 0);
    }
  }
  @media (max-width: 1250px) {
    & .previousButton {
      transform: translate(-40px, 0);
    }
    & .nextButton {
      transform: translate(40px, 0);
    }
  }
  @media (max-width: 1200px) {
    & .carousel-button-group {
      transform: translate(0, -135px);
    }
    & .previousButton {
      transform: translate(-40px, 0);
      width: 2rem;
      height: 2rem;
    }
    & .nextButton {
      transform: translate(40px, 0);
      width: 2rem;
      height: 2rem;
    }
  }
  @media (max-width: 767px) {
    & .carousel-button-group {
      transform: translate(0, -120px);
    }
  }
  @media (max-width: 575px) {
    & .carousel-button-group {
      transform: translate(0, -150px);
    }
    & .previousButton {
      transform: translate(40px, 0);
    }
    & .nextButton {
      transform: translate(-40px, 0);
    }
  }
  @media (max-width: 470px) {
    & .previousButton {
      transform: translate(0px, 0);
    }
    & .nextButton {
      transform: translate(0px, 0);
    }
  }
  @media (max-width: 390px) {
    & .previousButton {
      display: none;
    }
    & .nextButton {
      display: none;
    }
  }
`;

export default StyledTips;
