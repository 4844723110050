import React, { useState,useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './index.css';
import parse from 'html-react-parser';
import useTranslation from 'hooks/useTranslation';
import ReactPlayer from 'react-player';
import Thumbnail from './images/Thumbnail.jpg';
import PlayIcon from './images/PlayIcon.svg';

const thumbnail = `${process.env.REACT_APP_CDN_IMAGES_THUMBNAILS}${process.env.REACT_APP_KEYSTEPS_YT_VIDEO_ID}.jpg`;

const KeySteps = () => {
  const { t } = useTranslation();

  const handleOnPlay = () => {
    const parent = document.getElementsByClassName('keysteps-react-player')[0];
    parent.classList.add('is-playing');
  };
  return (
    <>
      <div className="keysteps-main">
        <div className="keysteps-bg"></div>
        <Row className="keysteps-content-row">
          <Col lg={8} md={10} sm={12} xs={12} className="keysteps-content-main-col">
            <Container className="keysteps-container">
              <Row className="keysteps-container-row">
                <Col lg={4} md={4} sm={11} xs={10} className="left-content padd-0-columns">
                  {parse(t('keysteps.title'))}
                </Col>
                <Col
                  lg={7}
                  md={8}
                  sm={11}
                  xs={10}
                  className="keysteps-react-player-col padd-0-columns"
                >
                  <div className="dot-matrix-bg"></div>
                  <ReactPlayer
                    className="keysteps-react-player"
                    width="100%"
                    height="100%"
                    playing={true}
                    controls={true}
                    url={process.env.REACT_APP_KEYSTEPS_YT_VIDEO}
                    stopOnUnmount={true}
                    muted={false}
                    volume={1}
                    light={thumbnail}
                    playIcon={<img className="play-icon" src={PlayIcon} alt="playIcon" />}
                    onPlay={() => handleOnPlay()}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default KeySteps;
