/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

const charCodeOfDot = '.'.charCodeAt(0);
const toString = Object.prototype.toString;
const reEscapeChar = /\\(\\)?/g;
const INFINITY = 1 / 0;
const reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/;
const reIsPlainProp = /^\w*$/;
const rePropName = RegExp(
  // Match anything that isn't a dot or bracket.
  '[^.[\\]]+' +
  '|' +
  // Or match property names within brackets.
  '\\[(?:' +
  // Match a non-string expression.
  '([^"\'][^[]*)' +
  '|' +
  // Or match strings (supports escaping characters).
  '(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2' +
  ')\\]' +
  '|' +
  // Or match "" as the space between consecutive dots or empty brackets.
  '(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))',
  'g'
);

function getTag(value) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString.call(value);
}

function isSymbol(value) {
  const type = typeof value;
  return (
    type === 'symbol' || (type === 'object' && value != null && getTag(value) === '[object Symbol]')
  );
}

function toKey(value) {
  if (typeof value === 'string' || isSymbol(value)) {
    return value;
  }
  const result = `${value}`;
  return result === '0' && 1 / value === -INFINITY ? '-0' : result;
}

function isKey(value, object) {
  if (Array.isArray(value)) {
    return false;
  }
  const type = typeof value;
  if (type === 'number' || type === 'boolean' || value == null || isSymbol(value)) {
    return true;
  }
  return (
    reIsPlainProp.test(value) ||
    !reIsDeepProp.test(value) ||
    (object != null && value in Object(object))
  );
}

const stringToPath = (string) => {
  const result = [];
  if (string.charCodeAt(0) === charCodeOfDot) {
    result.push('');
  }
  string.replace(rePropName, (match, expression, quote, subString) => {
    let key = match;
    if (quote) {
      key = subString.replace(reEscapeChar, '$1');
    } else if (expression) {
      key = expression.trim();
    }
    result.push(key);
  });
  return result;
};

function castPath(value, object) {
  if (Array.isArray(value)) {
    return value;
  }
  return isKey(value, object) ? [value] : stringToPath(value);
}

function baseGet(object, path) {
  path = castPath(path, object);

  let index = 0;
  const length = path.length;

  while (object != null && index < length) {
    object = object[toKey(path[index++])];
  }
  return index && index === length ? object : undefined;
}

export function get(object, path, defaultValue) {
  const result = object == null ? undefined : baseGet(object, path);
  return result === undefined ? defaultValue : result;
}

export function detectLanguage() {
  return (
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage
  );
}

/**
 * Capitalizes the first letter of each word in a given string.
 *
 * @param {string} inputString - The string to capitalize.
 * @return {string} The modified string with the first letter of each word capitalized.
 */
export function capitalizeFirstLetter(inputString) {
  if (!inputString || typeof inputString !== 'string') {
    return '';
  }

  // Convert the entire string to lowercase
  const lowercaseString = inputString.toLowerCase();

  // Split the string into words using spaces
  const words = lowercaseString.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Concatenate the modified words to form the final string
  return capitalizedWords.join(' ');
}

/**
 * @author Pratik Kajrolkar
 * @date 07/20/2023
 *
 * Formats a phone number by removing non-digit characters and converting it to the desired format.
 * @param {String} number - The phone number to be formatted.
 * @return {string} The formatted phone number in the format (XXX) XXX-XXXX, or the original input if
 * the number does not match the expected format.
 */
export function formatPhoneNumber(number) {
  // Remove all non-digit characters
  const cleaned = ('' + number).replace(/\D/g, '');

  // Extract the different parts of the phone number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Format the phone number in the desired format
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  // If the number does not match the expected format, return the original input
  return number;
}

/**
 * @author Pratik Kajrolkar
 * @date 07/20/2023
 *
 * Adds the international code to a phone number if it does not already have it.
 * @param {String} number - The phone number to add the international code to.
 * @param {String} countryCode - The international country code.
 * @return {String} - The phone number with the international code added.
 */
export function addInternationalCode(number, countryCode) {
  // Remove all non-digit characters
  const cleanedNumber = ('' + number).replace(/\D/g, '');

  // Check if the cleaned number already starts with the country code
  if (cleanedNumber.startsWith(countryCode)) {
    return cleanedNumber;
  }

  // Add the country code to the number
  return countryCode + cleanedNumber;
}

/**
 * @author Pratik Kajrolkar
 * @date 08/04/2023
 *
 * Determines whether the device accessing the page is a mobile, tablet, or desktop.
 *
 * @return {String} Returns "Mobile" if the device is a mobile, "Tablet" if the device is a tablet, or "Desktop" if the device is a desktop.
 */
export function isMobileOrTablet() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isMobile = width <= 768 && height <= 1024; // Adjust the values as needed
  const isTablet = width > 768 && width <= 991; // Adjust the values as needed

  if (isMobile) {
    return true;
  } else if (isTablet) {
    return true;
  } else {
    return false;
  }
}
